import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react';

import {useTranslation} from 'react-i18next';

import {useSelector, useDispatch} from 'react-redux';
import {produtoRequest} from 'store/ducks/produto/thunks';
import {Creators as PedidoActions} from 'store/ducks/pedido';

import ProductRepository from 'repository/product';
import PedidoRepository from 'repository/pedido';

import notification from 'providers/notification';

import {useDebounce} from 'hooks/useDebounce';

import {ProductModal} from 'components/productModal';
import {Loading} from 'components/loading';
import {PullToRefresh} from 'components/pullToRefresh';

import {validateExpiryTime} from 'services/validateExpiryTime';
import {validateHourOpen, hourInRange} from 'services/validateHourOpen';

import Header from 'routes/partials/header';
import Main from 'routes/partials/main';
import Footer from 'routes/partials/footer';

import {TagClosed} from 'components/tagClosed';
import {Error} from 'components/error';
import {HeaderInfo} from 'components/headerInfo';
import {ProductItem} from './components/productItem';
import {SearchBar} from './components/searchBar';
import {CategoryBar} from './components/categoryBar';

import {
  Container,
  ContainerContent,
  ContainerList,
  ContainerTop,
  ListView,
  ListEmpty,
  TagContainer,
} from './styles';

const Products = () => {
  const [state, setState] = useState({
    search: '',
    searchVisible: false,
    modalVisible: false,
    productSelected: null,
    categoryActive: null,
    subcategoryActive: null,
  });

  const debouncedSearch = useDebounce(state.search, 600);

  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const searchBarRef = useRef();

  const {serie, pdv, conta} = useSelector(
    reduxState => reduxState.config.config,
  );
  const {loading, produtos, error, done, refreshing} = useSelector(
    reduxState => reduxState.produto,
  );

  const {categoryActive, subcategoryActive, search, searchVisible} = state;

  const loadProducts = useCallback(
    (refresh = true) => {
      const lng = i18n.language;
      return dispatch(produtoRequest({refresh, pdv, serie, lng}));
    },
    [dispatch, pdv, serie, i18n.language],
  );

  useEffect(() => {
    if (!done) {
      const refresh = false;
      loadProducts(refresh);
    }
  }, [loadProducts, done]);

  useEffect(() => {
    if (searchVisible && searchBarRef.current) {
      setTimeout(() => {
        searchBarRef.current.focus();
      }, 300);
    }
  }, [searchVisible]);

  const handleRefresh = () => {
    const refresh = true;
    return new Promise(resolve => {
      if (!loading && !refreshing) {
        resolve(loadProducts(refresh));
      }
      resolve(true);
    });
  };

  const pedidoAdd = useCallback(
    item => {
      dispatch(PedidoActions.pedidoAdd(item));
    },
    [dispatch],
  );

  const handlePedidoAdd = ({
    product,
    amount,
    observation,
    observations,
    additionals,
  }) => {
    validateExpiryTime()
      .then(() => {
        validateHourOpen()
          .then(() => {
            const order = PedidoRepository.createOrder({
              product,
              amount,
              observation,
              observations,
              account: conta,
              additionals,
            });

            setState(prevState => ({
              ...prevState,
              modalVisible: false,
              productSelected: null,
            }));

            setTimeout(() => {
              pedidoAdd(order);
            }, 200);

            notification.success(t('cart.cardAdd'), t('cart.success'));
          })
          .catch(() => {
            setState(prevState => ({
              ...prevState,
              modalVisible: false,
              productSelected: null,
            }));
          });
      })
      .catch(() => {
        setState(prevState => ({
          ...prevState,
          modalVisible: false,
          productSelected: null,
        }));
      });
  };

  const renderModal = () => {
    const {modalVisible, productSelected} = state;
    if (!productSelected) {
      return null;
    }
    return (
      <ProductModal
        isOpen={modalVisible}
        product={productSelected}
        handleClose={() => {
          setState(prevState => ({
            ...prevState,
            modalVisible: false,
            productSelected: null,
          }));
        }}
        onConfirm={({
          product,
          amount,
          observation,
          observations,
          additionals,
        }) => {
          handlePedidoAdd({
            product,
            amount,
            observation,
            observations,
            additionals,
          });
        }}
      />
    );
  };

  const renderTagClosed = () => {
    const inRange = hourInRange();
    return !inRange ? (
      <TagContainer>
        <TagClosed />
      </TagContainer>
    ) : null;
  };

  const categories = useMemo(
    () => ProductRepository.getCategories(produtos),
    [produtos],
  );

  const subcategories = useMemo(
    () => ProductRepository.getSubcategories(produtos, categoryActive),
    [produtos, categoryActive],
  );

  const products = useMemo(
    () =>
      ProductRepository.getProducts(
        produtos,
        categoryActive,
        subcategoryActive,
        debouncedSearch,
      ),
    [produtos, categoryActive, subcategoryActive, debouncedSearch],
  );

  return (
    <>
      <Header
        showHomeButton
        showSearchButton
        onPressSearch={() => {
          setState(prevState => ({
            ...prevState,
            searchVisible: !searchVisible,
            search: '',
          }));
        }}
      />
      <Main hasFooter hasHeader>
        <Container>
          {renderTagClosed()}
          <HeaderInfo />
          {searchVisible ? (
            <SearchBar
              ref={searchBarRef}
              disabled={loading}
              value={search}
              onChange={value => {
                setState(prevState => ({
                  ...prevState,
                  search: value,
                }));
              }}
            />
          ) : null}

          <>
            {loading && !refreshing ? <Loading /> : null}

            {error && !loading && !refreshing ? (
              <Error
                title={t('error.product')}
                onPress={() => {
                  loadProducts(false);
                }}
              />
            ) : null}

            {!error ? (
              <ContainerContent>
                <ContainerTop>
                  {categories && categories.length > 0 && !search ? (
                    <CategoryBar
                      categories={categories}
                      categoryActive={categoryActive}
                      handleChangeCategory={category => {
                        setState(prevState => ({
                          ...prevState,
                          categoryActive: category,
                          subcategoryActive: null,
                        }));
                      }}
                    />
                  ) : null}

                  {subcategories && subcategories.length > 0 && !search ? (
                    <CategoryBar
                      categories={subcategories}
                      categoryActive={subcategoryActive}
                      handleChangeCategory={category => {
                        setState(prevState => ({
                          ...prevState,
                          subcategoryActive: category,
                        }));
                      }}
                    />
                  ) : null}
                </ContainerTop>

                <ContainerList>
                  <PullToRefresh handleRefresh={handleRefresh} isPullable>
                    <ListView>
                      {products && products.length > 0
                        ? products.map(product => (
                            <ProductItem
                              product={product}
                              key={product.codigo}
                              onPress={() => {
                                setState(prevState => ({
                                  ...prevState,
                                  modalVisible: true,
                                  productSelected: product,
                                }));
                              }}
                            />
                          ))
                        : null}

                      {(!products || products.length === 0) && !loading ? (
                        <ListEmpty>
                          <p>{t('product.empty')}</p>
                        </ListEmpty>
                      ) : null}
                    </ListView>
                  </PullToRefresh>
                </ContainerList>
              </ContainerContent>
            ) : null}

            {renderModal()}
          </>
        </Container>
      </Main>
      <Footer />
    </>
  );
};

Products.defaultProps = {};

Products.propTypes = {};

export default Products;
