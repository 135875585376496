import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {LazyLoadComponent} from 'react-lazy-load-image-component';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {FaPlus, FaMinus} from 'react-icons/fa';

import NotFoundImage from 'assets/img/not-found.png';
import ComboIcon from 'assets/img/combo.png';

import {Alert} from 'providers/alert';

import {limitString} from 'helpers/string';

import {colors} from 'styles';
import {produtoType} from 'types';

import {Combo} from './components/combo';
import {Additional} from './components/additional';

import {
  populateCombo,
  updateCombo,
  updateAdditional,
  transformAdditionals,
} from './util';
import {validateComboLevels} from './validate';

import {
  Container,
  ProductContent,
  ProductScroll,
  ProductTitle,
  ProductPrice,
  ProductDescription,
  ProductAddContainer,
  ProductAddContainerLeft,
  ProductAddContainerLeftContent,
  ProductAddContainerRight,
  ProductRightAddTitle,
  ProductAmount,
  ProductIncreaseDecresaButton,
  ImagemContainer,
  Imagem,
  ProductObsContainer,
  ProductObsInput,
  TaxInfo,
  ComboImg,
  ComboContainer,
  TagContainer,
  Tag,
  TagTitle,
  AdditionalContainer,
  AdditionalTitle,
} from './styles';

export const ProductModal = ({
  isEdit,
  isOpen,
  handleClose,
  product,
  onConfirm,
  amount,
  observation,
  observations,
}) => {
  const INITIAL_STATE_ADDITIONALS = useMemo(() => {
    if (product?.adicionais && product?.adicionais.length > 0) {
      const transformedItems = transformAdditionals(product.adicionais);
      return transformedItems;
    }
    return [];
  }, [product]);

  const {t} = useTranslation();
  const {bloquearpedido, pagamento} = useSelector(state => state.config.config);
  const [qtd, setQtd] = useState(amount || 1);
  const [obs, setObs] = useState(observation || '');
  const [obss, setObss] = useState(observations || []);
  const [productCombo, setProductCombo] = useState(null);
  const [additionals, setAdditionals] = useState(INITIAL_STATE_ADDITIONALS);

  const isCombo = useMemo(
    () => product?.combo && product?.combo.length > 0,
    [product],
  );

  const screenHeight = useMemo(() => window.screen.height, []);

  useEffect(() => {
    if (product && isCombo && isOpen) {
      const productHydrated = populateCombo(product);

      setProductCombo(productHydrated);
    }
  }, [product, isCombo, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setQtd(1);
      setObs('');
      setObss([]);
      setProductCombo(null);
    }
  }, [isOpen]);

  const handleAddComboItem = (comboLevelOneIndex, comboLevelTwoIndex, max) => {
    const comboUpdated = updateCombo(
      productCombo,
      comboLevelOneIndex,
      comboLevelTwoIndex,
      max,
    );
    setProductCombo(comboUpdated);
  };

  const handleConfirm = () => {
    if (isCombo) {
      validateComboLevels(productCombo)
        .then(() => {
          onConfirm({
            product: {...product, combo: productCombo},
            amount: qtd,
            observation: obs,
            observations: obss,
          });
        })
        .catch(comboError => {
          Alert({
            title: `${t('combo.error_title')}`,
            text: `(${comboError.join(', ')}) ${t('combo.error_msg')}`,
            icon: 'warning',
          });
        });
    } else {
      onConfirm({
        product,
        amount: qtd,
        observation: obs,
        observations: obss,
        additionals,
      });
    }
  };

  const renderObservations = () => {
    if (product?.obs && product?.obs.length > 0) {
      return (
        <TagContainer>
          {product.obs.map(o => {
            if (o.codigo && o.nome) {
              const exist = obss.some(s => s.codigo === o.codigo);
              return (
                <Tag
                  key={o.codigo}
                  onClick={() => {
                    if (exist) {
                      setObss(prevState =>
                        prevState.filter(v => v.codigo !== o.codigo),
                      );
                    } else {
                      setObss(prevState => [...prevState, o]);
                    }
                  }}
                  $active={exist}>
                  <TagTitle $active={exist}>{o.nome}</TagTitle>
                </Tag>
              );
            }
            return null;
          })}
        </TagContainer>
      );
    }
    return null;
  };

  const renderAdditionals = () => {
    if (additionals && additionals.length > 0) {
      return (
        <AdditionalContainer>
          <AdditionalTitle>{t('combo.add')}</AdditionalTitle>
          {additionals.map((ad, i) => (
            <Additional
              moeda={pagamento.moeda}
              key={`${ad.codigo}`}
              qtd={ad.qtd}
              name={ad.nome}
              price={ad.valor}
              increase={() => {
                setAdditionals(prevState =>
                  updateAdditional(prevState, i, 'INCREASE'),
                );
              }}
              decrease={() => {
                setAdditionals(prevState =>
                  updateAdditional(prevState, i, 'DECREASE'),
                );
              }}
            />
          ))}
        </AdditionalContainer>
      );
    }
    return null;
  };

  return (
    <Modal
      unmountOnClose
      fade
      centered
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        handleClose();
      }}
      backdrop>
      <ModalHeader
        toggle={() => {
          handleClose();
        }}>
        {limitString(`${product.nome}`, 22)}
      </ModalHeader>
      <ModalBody>
        {product ? (
          <Container>
            <ProductScroll $maxHeight={screenHeight - 150}>
              <ImagemContainer>
                {isCombo ? <ComboImg src={ComboIcon} alt="combo" /> : null}

                <LazyLoadComponent height="100%">
                  <Imagem
                    src={product.img ? product.img : NotFoundImage}
                    alt={product.img}
                  />
                </LazyLoadComponent>
              </ImagemContainer>

              <ProductContent>
                <ProductTitle>{product.nome}</ProductTitle>
                <ProductPrice>
                  {pagamento.moeda} {product.valor}
                </ProductPrice>
                <ProductDescription>{product.descricao}</ProductDescription>

                {isCombo ? (
                  <ComboContainer>
                    <Combo
                      productCombo={productCombo}
                      handleAddComboItem={handleAddComboItem}
                    />
                  </ComboContainer>
                ) : null}

                <ProductObsContainer>
                  <ProductObsInput
                    autoComplete="off"
                    onChange={event => {
                      setObs(event.target.value);
                    }}
                    value={obs}
                    placeholder={t('product.obs')}
                    type="textarea"
                    name="obs"
                    id="obs"
                    disabled={false}
                    maxLength={40}
                    rows={3}
                  />
                </ProductObsContainer>

                {renderObservations()}

                {renderAdditionals()}

                <TaxInfo>{t('product.taxInfo')}</TaxInfo>
              </ProductContent>
            </ProductScroll>

            {!bloquearpedido ? (
              <ProductAddContainer>
                <ProductAddContainerLeft>
                  <ProductAddContainerLeftContent>
                    <ProductIncreaseDecresaButton
                      onClick={() => {
                        if (qtd > 1) {
                          setQtd(qtd - 1);
                        }
                      }}>
                      <FaMinus color={colors.background} size={20} />
                    </ProductIncreaseDecresaButton>
                    <ProductAmount>{qtd}</ProductAmount>
                    <ProductIncreaseDecresaButton
                      onClick={() => {
                        setQtd(qtd + 1);
                      }}>
                      <FaPlus color={colors.background} size={20} />
                    </ProductIncreaseDecresaButton>
                  </ProductAddContainerLeftContent>
                </ProductAddContainerLeft>
                <ProductAddContainerRight
                  onClick={() => {
                    handleConfirm();
                  }}>
                  <ProductRightAddTitle>
                    {isEdit ? t('product.edit') : t('product.add')}
                  </ProductRightAddTitle>
                </ProductAddContainerRight>
              </ProductAddContainer>
            ) : null}
          </Container>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

ProductModal.defaultProps = {
  product: null,
  amount: 1,
  observation: '',
  observations: [],
  isEdit: false,
};

ProductModal.propTypes = {
  product: PropTypes.shape(produtoType),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  amount: PropTypes.number,
  observation: PropTypes.string,
  observations: PropTypes.arrayOf(PropTypes.shape({})),
  isEdit: PropTypes.bool,
};
