import React from 'react';
import PropTypes from 'prop-types';
import {FaHome, FaSearch} from 'react-icons/fa';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import LogoIService from 'assets/img/iService 590x85.png';

import {colors} from 'styles';

import {
  HeaderContainer,
  HeaderImageContainer,
  ImageLogo,
  HeaderButtonContainer,
  HeaderButton,
} from './styles';

const Header = ({showHomeButton, showSearchButton, onPressSearch}) => {
  const {logo} = useSelector(state => state.config.config);
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderButtonContainer>
        {showHomeButton ? (
          <HeaderButton
            onClick={() => {
              navigate('/dashboard', {replace: true});
            }}>
            <FaHome color={colors.white} size={20} />
          </HeaderButton>
        ) : null}
      </HeaderButtonContainer>
      <HeaderImageContainer>
        <ImageLogo
          src={logo || LogoIService}
          className="img-fluid"
          alt="iservice"
        />
      </HeaderImageContainer>
      <HeaderButtonContainer>
        {showSearchButton ? (
          <HeaderButton
            onClick={() => {
              onPressSearch();
            }}>
            <FaSearch color={colors.white} size={20} />
          </HeaderButton>
        ) : null}
      </HeaderButtonContainer>
    </HeaderContainer>
  );
};

Header.defaultProps = {
  showHomeButton: false,
  showSearchButton: false,
  onPressSearch: undefined,
};

Header.propTypes = {
  showHomeButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  onPressSearch: PropTypes.func,
};

export default Header;
