import React from 'react';
import PropTypes from 'prop-types';
import {FaAngleDoubleDown} from 'react-icons/fa';

import {useTranslation} from 'react-i18next';

import Spinner from 'react-spinkit';

import ReactSimplePullToRefresh from 'react-simple-pull-to-refresh';

import {colors} from 'styles';

import {RefreshingContent, PullingContent, ScrollView} from './styles';

export const PullToRefresh = ({children, handleRefresh, isPullable}) => {
  const {t} = useTranslation();

  return (
    <ReactSimplePullToRefresh
      isPullable={isPullable}
      onRefresh={handleRefresh}
      canFetchMore={false}
      refreshingContent={
        <RefreshingContent>
          <Spinner name="ball-beat" color={colors.blue} />
        </RefreshingContent>
      }
      pullingContent={
        <PullingContent>
          <p className="pull-title">{t('actions.pullToRefresh')}</p>
          <FaAngleDoubleDown color={colors.blue} size={20} />
        </PullingContent>
      }>
      <ScrollView>{children}</ScrollView>
    </ReactSimplePullToRefresh>
  );
};

PullToRefresh.defaultProps = {
  children: null,
  isPullable: true,
};

PullToRefresh.propTypes = {
  children: PropTypes.element,
  isPullable: PropTypes.bool,
  handleRefresh: PropTypes.func.isRequired,
};
