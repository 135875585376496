import React from 'react';
import PropTypes from 'prop-types';

import Diners from 'assets/img/flags/diners.svg';
import Discover from 'assets/img/flags/discover.svg';
import Elo from 'assets/img/flags/elo.svg';
import Mastercard from 'assets/img/flags/mastercard.svg';
import Hipercard from 'assets/img/flags/hipercard.svg';
import Jcb from 'assets/img/flags/jcb.svg';
import Visa from 'assets/img/flags/visa.svg';
import Amex from 'assets/img/flags/amex.svg';

import MercadoPago from 'assets/img/mercadopago.png';

const cardStyle = {
  width: 45,
  height: 'auto',
  marginRight: 10,
  marginBottom: 10,
};

export const Flags = ({acceptCards, isMercadoPago}) => (
  <>
    <div style={{marginTop: 20}}>
      {isMercadoPago ? (
        <img
          src={MercadoPago}
          alt="MercadoPago"
          style={{width: 150, marginTop: 20}}
        />
      ) : null}
    </div>
    <div style={{marginTop: 20}}>
      {acceptCards.includes('diners') ? (
        <img src={Diners} alt="Diners" style={cardStyle} />
      ) : null}
      {acceptCards.includes('discover') ? (
        <img src={Discover} alt="Discover" style={cardStyle} />
      ) : null}
      {acceptCards.includes('elo') ? (
        <img src={Elo} alt="Elo" style={cardStyle} />
      ) : null}
      {acceptCards.includes('mastercard') ? (
        <img src={Mastercard} alt="Mastercard" style={cardStyle} />
      ) : null}
      {acceptCards.includes('hipercard') ? (
        <img src={Hipercard} alt="Hipercard" style={cardStyle} />
      ) : null}
      {acceptCards.includes('jcb') ? (
        <img src={Jcb} alt="Jcb" style={cardStyle} />
      ) : null}
      {acceptCards.includes('visa') ? (
        <img src={Visa} alt="Visa" style={cardStyle} />
      ) : null}
      {acceptCards.includes('amex') ? (
        <img src={Amex} alt="Amex" style={cardStyle} />
      ) : null}
    </div>
  </>
);

Flags.defaultProps = {
  acceptCards: [],
  isMercadoPago: false,
};

Flags.propTypes = {
  acceptCards: PropTypes.arrayOf(PropTypes.string),
  isMercadoPago: PropTypes.bool,
};
