import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  CategoryButton,
  CategoryTitle,
  CategoryContainer,
} from './styles';

const CategoryItem = ({title, onClick, active}) => (
  <CategoryButton onClick={onClick}>
    <CategoryTitle $active={active}>{title}</CategoryTitle>
  </CategoryButton>
);

CategoryItem.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const CategoryBar = ({
  categories,
  categoryActive,
  handleChangeCategory,
}) => (
  <Container>
    <CategoryContainer>
      {categories.map(category => (
        <CategoryItem
          active={
            !!categoryActive &&
            String(categoryActive.codigo) === String(category.codigo)
          }
          title={category.nome}
          key={category.codigo}
          onClick={() => {
            handleChangeCategory(category);
          }}
        />
      ))}
    </CategoryContainer>
  </Container>
);

CategoryBar.defaultProps = {
  categoryActive: null,
  categories: [],
};

CategoryBar.propTypes = {
  categoryActive: PropTypes.shape({
    codigo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  handleChangeCategory: PropTypes.func.isRequired,
};
