import React from 'react';
import PropTypes from 'prop-types';
import {FaArrowLeft} from 'react-icons/fa';

import {useNavigate} from 'react-router-dom';

import {colors} from 'styles';
import {
  HeaderContainer,
  HeaderButtonBackContainer,
  HeaderTitleContainer,
  HeaderTitle,
} from './styles';

const HeaderBack = ({title}) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderButtonBackContainer
        onClick={() => {
          navigate(-1);
        }}>
        <FaArrowLeft color={colors.grayDark} size={20} />
      </HeaderButtonBackContainer>
      <HeaderTitleContainer>
        <HeaderTitle>{title}</HeaderTitle>
      </HeaderTitleContainer>
    </HeaderContainer>
  );
};

HeaderBack.defaultProps = {
  title: '',
};

HeaderBack.propTypes = {
  title: PropTypes.string,
};

export default HeaderBack;
