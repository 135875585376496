import React from 'react';
import PropTypes from 'prop-types';

import {Container} from './styles';

const Main = ({children, hasHeader, hasFooter}) => (
  <Container $hasHeader={hasHeader} $hasFooter={hasFooter}>
    <>{children}</>
  </Container>
);

export default Main;

Main.defaultProps = {
  hasHeader: false,
  hasFooter: false,
};

Main.propTypes = {
  children: PropTypes.element.isRequired,
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
};
