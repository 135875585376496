import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {formatMoney, unformat} from 'helpers/number';
import {
  Container,
  Content,
  ColLeft,
  TotalText,
  TotalTextBold,
  ColRight,
  TotalResult,
  TotalResultBold,
  PaymentButton,
  PaymentButtonTitle,
} from './styles';

export const ExtractFooter = ({total, loading, onClick, showPayment}) => {
  const {t} = useTranslation();
  const {moeda} = useSelector(state => state.config.config.pagamento);
  const totalToPay = useMemo(
    () =>
      unformat(total.total) -
      unformat(total.adiantamentos) -
      unformat(total.totalCortesias) -
      unformat(total.totalRetencoes),
    [total],
  );

  const disabled = useMemo(() => totalToPay < 0, [totalToPay]);

  return (
    <Container>
      <Content>
        <ColLeft>
          <TotalText>
            {t('extract.totalAccount')} {moeda} {' (+)'}
          </TotalText>
        </ColLeft>
        <ColRight>
          <TotalResult>{loading ? '---' : total.totalBruto}</TotalResult>
        </ColRight>
      </Content>

      {unformat(total.totalTaxa) > 0 ? (
        <Content>
          <ColLeft>
            <TotalText>
              {t('extract.totalTax')} {moeda} {' (+)'}
            </TotalText>
          </ColLeft>
          <ColRight>
            <TotalResult>{loading ? '---' : total.totalTaxa}</TotalResult>
          </ColRight>
        </Content>
      ) : null}

      {unformat(total.adiantamentos) > 0 ? (
        <Content>
          <ColLeft>
            <TotalText>
              {t('extract.adiantamentos')} {moeda} {' (-)'}
            </TotalText>
          </ColLeft>
          <ColRight>
            <TotalResult>{loading ? '---' : total.adiantamentos}</TotalResult>
          </ColRight>
        </Content>
      ) : null}

      {unformat(total.totalCortesias) > 0 ? (
        <Content>
          <ColLeft>
            <TotalText>
              {t('extract.cortesia')} {moeda} {' (-)'}
            </TotalText>
          </ColLeft>
          <ColRight>
            <TotalResult>{loading ? '---' : total.totalCortesias}</TotalResult>
          </ColRight>
        </Content>
      ) : null}

      {unformat(total.totalDescontos) > 0 ? (
        <Content>
          <ColLeft>
            <TotalText>
              {t('extract.descontos')} {moeda} {' (-)'}
            </TotalText>
          </ColLeft>
          <ColRight>
            <TotalResult>{loading ? '---' : total.totalDescontos}</TotalResult>
          </ColRight>
        </Content>
      ) : null}

      {unformat(total.totalRetencoes) > 0 ? (
        <Content>
          <ColLeft>
            <TotalText>
              {t('extract.retencoes')} {moeda} {' (-)'}
            </TotalText>
          </ColLeft>
          <ColRight>
            <TotalResult>{loading ? '---' : total.totalRetencoes}</TotalResult>
          </ColRight>
        </Content>
      ) : null}

      <Content>
        <ColLeft>
          <TotalTextBold>
            {t('extract.total')} {moeda}
          </TotalTextBold>
        </ColLeft>
        <ColRight>
          <TotalResultBold>
            {loading ? '---' : formatMoney(totalToPay)}
          </TotalResultBold>
        </ColRight>
      </Content>
      {showPayment ? (
        <Content>
          <PaymentButton
            disabled={disabled}
            onClick={() => {
              if (!loading && !disabled) {
                onClick();
              }
            }}>
            <PaymentButtonTitle>{t('extract.pay')}</PaymentButtonTitle>
          </PaymentButton>
        </Content>
      ) : null}
    </Container>
  );
};

ExtractFooter.defaultProps = {
  showPayment: false,
};

ExtractFooter.propTypes = {
  showPayment: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.shape({
    total: PropTypes.string,
    adiantamentos: PropTypes.string,
    totalCortesias: PropTypes.string,
    totalRetencoes: PropTypes.string,
    totalBruto: PropTypes.string,
    totalTaxa: PropTypes.string,
    totalDescontos: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
