import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {FaSearch} from 'react-icons/fa';

import {colors} from 'styles';
import {Container, InputContainer, SearchInput} from './styles';

export const SearchBar = forwardRef(({disabled, value, onChange}, ref) => {
  const {t} = useTranslation();
  return (
    <Container>
      <InputContainer>
        <FaSearch color={colors.blue} size={20} />
        <SearchInput
          innerRef={ref}
          autoComplete="off"
          onChange={event => {
            onChange(event.target.value);
          }}
          value={value}
          placeholder={t('product.search')}
          type="search"
          name="text"
          id="search"
          disabled={disabled}
          maxLength={15}
        />
      </InputContainer>
    </Container>
  );
});

SearchBar.defaultProps = {
  value: '',
};

SearchBar.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
