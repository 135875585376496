import React from 'react';
import PropTypes from 'prop-types';

import {Button as Btn, ButtonTitle as BtnTitle} from './styles';

export const IButton = ({title, disabled, type, onClick}) => (
  <Btn
    disabled={disabled}
    type={type}
    onClick={() => {
      onClick();
    }}>
    <BtnTitle>{title}</BtnTitle>
  </Btn>
);

IButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  type: 'button',
};

IButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
};
