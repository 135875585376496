import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export const Alert = (options = {}, callbackFunction = () => {}) => {
  Swal.fire({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    ...options,
  }).then(result => {
    if (callbackFunction) {
      callbackFunction(result);
    }
  });
};
